import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

$(function() {
  $(document).ready(function() {
    init();
    jumpHash();
    toggleMenu();
    fadeScrolltTop();
    fadeIn();
  });

/////////////////////////// resize
  $(window).on('resize', onResize);
  function onResize() {

  }

/////////////////////////// scroll
  $(window).on('scroll', onScroll);
  function onScroll() {
    fadeScrolltTop();
    fadeIn();
  }

  function init(){
		var winW = $(window).width(),
	    winH = $(window).height();
    //ローディング画面
    $('.wrapper').css('display','none');
    $('#loading ,#loader').height(winH).css('display','block');
    $('#loading').delay(300).fadeOut(300); 
    $('.wrapper').css('display', 'block');
  }

  const jumpHash = () => {
		
    $('a[href^="#"]').click(function() {
    const speed = 1000;
    const href= $(this).attr('href');
    const target = $(href == '#' || href == '' ? 'html' : href);
    let position = target.offset().top;

    $('html, body').animate({scrollTop:position}, speed, 'swing');
    return false;
		});
  };

  const toggleMenu = () => {
		$('.la-header__hamburger').click(function(){
			if($(this).hasClass('open')) {
        $(this).removeClass('open');
				$('.la-header__spgnavList').removeClass('is-open');
			} else {
        $(this).addClass('open');
				$('.la-header__spgnavList').addClass('is-open');
			}
    });
    
    $('.la-header__spgnavList .la-header__menuItemText').click(function(){
      $('.la-header__hamburger').removeClass('open');
      $('.la-header__spgnavList').removeClass('is-open');
    });
  }

  const fadeScrolltTop = () => {
    if($(window).scrollTop() + $(window).height() > $('.la-flow').offset().top) {
      $('.la-footer__scrolltop').addClass('is-show');
    } else {
      $('.la-footer__scrolltop').removeClass('is-show');
    }
  }

  const fadeIn = () => {
		let scroll = $(window).scrollTop();
		let windowHeight = $(window).height();

		$(".inViewDown").each(function(){
			let imgPos = $(this).offset().top;
			if (scroll > imgPos - windowHeight + windowHeight/5){
        $(this).addClass("fadeInDown animated");
      }
		});
	}

});